.body-cl {
    background: #f2f3f7;
}

.staus5 {
    background-color: #ffe7ba;
    height          : 100%;
}

.staus7 {
    background-color: #f4ffb8;
    height          : 100%;
}